import { useRef,useState } from "react";
const AccordionItem = (props) => {
    const contentEl = useRef();
    const { handleToggle, active, faq } = props;
    const { answer, id, question } = faq;

    return (
        <div className="rc-accordion-card w3-animate-top">
            <div className="rc-accordion-header">
                <div className={`rc-accordion-toggle p-3 ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>
                    <h5 className="rc-accordion-title">{question}</h5>
                    <i className="fa fa-chevron-down rc-accordion-icon"></i>
                </div>
            </div>
            <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} style={
                active === id
                    ? { height: contentEl.current.scrollHeight }
                    : { height: "0px" }
            }>
                <div className="rc-accordion-body">
                    <p className='mb-0'>{answer}</p>
                </div>
            </div>
        </div>
    )
}

const App = () => {
    const [active, setActive] = useState(null);
    const faqs = [
        {
          id: 'one',
          question: 'What is Tally Prime?',
          answer: 'Tally Prime is a comprehensive business management software that helps you manage your accounting, inventory, taxation, banking, payroll, and other business needs efficiently.'
        },
        {
          id: 'two',
          question: 'How can Tally Prime benefit my business?',
          answer: 'Tally Prime simplifies financial management by offering tools for accounting, inventory tracking, payroll processing, and compliance, all within a user-friendly interface that adapts to the needs of your growing business.'
        },
        {
          id: 'three',
          question: 'What support does RC Services offer for Tally Prime?',
          answer: 'RC Services provides full support for Tally Prime, including software installation, training, and ongoing assistance to ensure you get the most out of your investment. Our team is always available to help you with any questions or issues that may arise.'
        }
      ];
      
    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }
    
    return (
        <>
            <div className="container-fluid mt-5 mb-5">
                <div className="row justify-content-center">
                    <div className="col-md-8 mt-2">
                        <div className="card">
                            <div className="card-body">
                              <h4 className="form-heading mb-4 text-primary text-center mt-3">FAQ</h4>
                                {faqs.map((faq, index) => {
                                     return (
                                            <AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default App