// NotFound.js

import React from 'react';
import { Link } from 'react-router-dom';  
const NotFound = () => {
  return (
    <div className="container text-center" style={{ marginTop: '100px', marginBottom: '100px' }}>
      <h1 className="w3-animate-fading">404 - Not Found!</h1>
      <p className="w3-animate-fading">Sorry, the page you are looking for does not exist.</p>
      <p className="w3-animate-fading">
        You can go back to the <Link to="/">home page</Link>.
      </p>
    </div>
  );
};

export default NotFound;
