import React from 'react'
import ProductCard from '../pages/ProductCard.js';
export default function Buypage() {
    const products = [
        {
            id: 1,
            name: 'SILVER',
            description: 'Single user edition For Standalone PCs',
            price: '22,500',
            gst: '+18% GST (INR 4,050)',
        },
        {
            id: 2,
            name: 'GOLD',
            description: 'Unlimited multi-user edition For multiple PCs on LAN environment',
            price: '67,500',
            gst: '+18% GST (INR 12,150)',
        },
        {
            id: 3,
            name: 'Tally Prime Server(TPS)',
            description: 'For medium and large enterprises (requires TallyPrime Gold license)',
            price: '2,70,000',
            gst: '+18% GST (INR 48,600)',
        },
        {
            id: 4,
            name: 'TSS Renewal SILVER',
            description: 'Single user edition For Standalone PCs',
            price: '4,500',
            gst: '+18% GST (INR 4,050)',
        },
        {
            id: 5,
            name: 'TSS Renewal GOLD',
            description: 'Unlimited multi-user edition For multiple PCs on LAN environment',
            price: '13,500',
            gst: '+18% GST (INR 2,430)',
        },{
            id: 6,
            name: 'TSS Renewal AUDITOR',
            description: 'This is applicable for Auditor edition only',
            price: '6,750',
            gst: '+18% GST (INR 1,215)',
        },
        {
            id: 7,
            name: 'TallyPrime SILVER To TallyPrime GOLD',
            description: '',
            price: '45,000',
            gst: '+18% GST (INR 8,100)',
        },
        {
            id: 8,
            name: 'PRE Tally.ERP9 GOLD To TallyPrime GOLD',
            description: '',
            price: '27,000',
            gst: '+18% GST (INR 4,860)',
        },
        {
            id: 9,
            name: 'PRE Tally.ERP 9 SILVER To TallyPrime GOLD',
            description: '',
            price: '49,500',
            gst: '+18% GST (INR 8,910)',
        },
    ];
    return (
        <div className="container mt-5">
            <h1 className="text-center">Products</h1>
            <br />
            <div className="row">
                {products.slice(0,3).map(product => <div key={product.id} className="col-md-4">
                        <ProductCard product={product} />
                    </div>)}
            </div>
            <h1 className="text-center">TSS Renewal</h1>
            <br />
            <div className="row">
                {products.slice(3,6).map(product => <div key={product.id} className="col-md-4">
                        <ProductCard product={product} />
                    </div>)}
            </div>
            <h1 className="text-center">TallyPrime Upgrade</h1>
            <br />
            <div className="row">
                {products.slice(6,9).map(product => <div key={product.id} className="col-md-4">
                        <ProductCard product={product} />
                    </div>)}
            </div>
        </div>
    );
}
