import React from 'react';
import Swal from 'sweetalert2';
// import { Link } from 'react-router-dom';

const ProductCard = ({ product }) => {
    return (
       <>
        <div className="card product-card mb-4">
            <div className="card-body">
                <img src="3.webp" alt="" style={{ width: '150px', height: '30px',display: 'block', marginLeft: 'auto', marginRight: 'auto' }}/>
                <br />
                <br />
                <h2 className="card-title text-center">{product.name}</h2>
                <br />
                <h5 className="card-text text-center">{product.description}</h5>
                <br /><br />
                <h1 className="card-price text-center" style={{color: "black", fontWeight: "bold"}}>INR {product.price}</h1>
                <p className="card-text text-center">{product.gst}</p>
                <button className="btn btn-warning d-block mx-auto" onClick={()=>{Swal.fire('Contact Us', 'at +91 8769538183');}}>Contact Us</button>
                {/* <Link to="/contact" className="btn btn-primary ms-2">Contact Us</Link>
                <Link to="mailto:tallyservices.ghy@gmail.com" className="btn btn-primary ms-2">Email Us</Link>
                <Link to="tel:+918769538183" className="btn btn-primary ms-2">Call Us</Link> */}
            </div>
        </div>
        </>
    );
};

export default ProductCard;