import React from 'react';
import Faq from '../components/Faq';
import CarouselComponent from './Carousel';
function HomePage() {
  return (
    <div className="container my-5 w3-animate-left">
      <header className="text-center mb-5">
        <h1>Welcome to RC Services</h1>
        <p className="lead">
          Your trusted partner in accounting software solutions. Discover how Tally Prime can simplify and enhance your business operations.
        </p>
      </header>
      <CarouselComponent/>
      <br />
      <br />
      <section className="mb-5">
        <h2 className="text-center">Why Choose Tally Prime?</h2>
        <div className="row align-items-center mt-4">
          <div className="col-md-6">
            <img
              src="logo.png"
              alt="Tally Prime Representation"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-md-6">
            <ul className="list-group">
              <li className="list-group-item">
                <h5>Comprehensive Business Management</h5>
                <p>Manage your accounting, inventory, payroll, and more with ease.</p>
              </li>
              <li className="list-group-item">
                <h5>Easy-to-Use Interface</h5>
                <p>Navigate complex business processes with a simple and intuitive design.</p>
              </li>
              <li className="list-group-item">
                <h5>Real-Time Reporting</h5>
                <p>Access insightful reports and dashboards that help you make informed decisions.</p>
              </li>
              <li className="list-group-item">
                <h5>Scalable Solutions</h5>
                <p>Whether you are a small business or growing enterprise, Tally Prime grows with you.</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Faq/>
    </div>
  );
}

export default HomePage;