import React from 'react';

function Footer() {
  return (
    <footer id="contact" className="bg-dark text-white text-center py-4">
      <div className="container">
        <p>&copy; {new Date().getFullYear()} RC Services. All Rights Reserved.</p>
        <p>Email- tallyservices.ghy@gmail.com<br/>Phone-  +91 8769538183</p>
      </div>
    </footer>
  );
}

export default Footer;
