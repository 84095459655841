import React from 'react';

function About() {
  return (
    <div id="about" className="container my-5 w3-animate-right">
      <h2 className="text-center">About RC Services</h2>
      <br />
      <p style={{ textAlign: 'justify' }}>
At RC Services, we are dedicated to empowering businesses with the right tools to manage their finances efficiently. As a trusted dealer of Tally Prime software, we provide comprehensive solutions tailored to meet the diverse needs of small and medium enterprises. Our mission is to simplify financial management by offering a seamless, user-friendly experience through Tally Prime’s powerful capabilities. 
<br/><br/>
With years of expertise in accounting software solutions, RC Services is committed to delivering exceptional customer Services and personalized support. We understand the challenges businesses face in today’s fast-paced environment, and we strive to equip our clients with the knowledge and resources needed to stay ahead. Our team of certified professionals is always ready to assist with implementation, training, and troubleshooting, ensuring that your transition to Tally Prime is smooth and successful.
<br/><br/>
At RC Services, your success is our priority. Whether you are looking to streamline your accounting processes, manage inventory, or gain insightful business reports, RC Services is here to guide you every step of the way. Partner with us, and experience the difference with Tally Prime – your trusted companion in business growth and efficiency.
      </p>
    </div>
  );
}

export default About;
