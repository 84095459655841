import React from 'react';

function SocialButtons() {
  return (
    <div className="col-sm-6 w3-animate-right">
      <h2 className="text-center mb-3">Connect with Us</h2>
      <p className="text-center">Follow us on social media for the latest updates and news!</p>
      <div className="row g-2 justify-content-center">
        {/* Instagram Button */}
        <div className="col-6 col-md-3">
          <a
            href="https://www.instagram.com/rcservices.tally?igsh=ZW8wY2x5aGp3OGIz"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-outline-danger w-100 d-flex align-items-center justify-content-center"
          >
            <i className="bi bi-instagram me-2"></i> Instagram
          </a>
        </div>

        {/* WhatsApp Button */}
        <div className="col-6 col-md-3">
          <a
            href="https://wa.me/918769538183"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-success w-100 d-flex align-items-center justify-content-center"
          >
            <i className="bi bi-whatsapp me-2"></i> WhatsApp
          </a>
        </div>

        {/* YouTube Button */}
        <div className="col-6 col-md-3">
          <a
            href="https://youtube.com/@rcservices6647?si=q8NE-hbwYn5ubpMm"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-danger w-100 d-flex align-items-center justify-content-center"
          >
            <i className="bi bi-youtube me-2"></i> YouTube
          </a>
        </div>
      </div>
    </div>
  );
}

export default SocialButtons;
