import Swal from "sweetalert2";

export default function animate() {
Swal.fire({
  title: "Loading...",
  timer: 1000,
  didOpen: () => {
    Swal.showLoading();
  },
})
}
