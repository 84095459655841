import React, { useState } from 'react';
import Swal from 'sweetalert2';
import Socialbtn from './Socialbtn';
import emailjs from "@emailjs/browser";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Name is required.';
    if (!formData.email) {
      errors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email address is invalid.';
    }
    if (!formData.message) errors.message = 'Message is required.';
    return errors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setFormData({ name: '', email: '', message: '' });
      emailjs.sendForm("service_star_solutions","template_rcservices",e.target,{publicKey:"M0OVs8j_loyaNMf66"}).then(res=>{
        Swal.fire({
          icon: 'success',
          title: 'Message Sent',
          text: 'Thank you for contacting us. We will get back to you as soon as possible.'
        })
      })
    }
  };
  return (
    <div className="container my-5">
      <h2 className="text-center mb-4 w3-animate-top">Get in Touch with RC Services</h2>
      <div className="row mb-4">
        <div className="col-md-6 w3-animate-left">
          <h4>Contact Information</h4>
          <p><strong>Email:</strong> <a href="mailto:tallyservices.ghy@gmail.com">tallyservices.ghy@gmail.com</a></p>
          <p><strong>Phone:</strong> <a href="tel:+918769538183">+91 8769538183</a>, <a href="tel:+916375161981">+91 6375161981</a></p>
          <p><strong>Address:</strong> Siddique Ali Commercial Complex, Sati Joymati Road, 
Athgaon, Guwahati, Assam, Pin-781001
</p>
          <p><strong>Office Hours:</strong> Monday - Saturday, 9 AM - 8 PM</p>
        </div>
        <Socialbtn/>
      </div>

      <div className="mt-5 w3-animate-right">
        <h4 className="text-center">Or Send Us a Message</h4>
        <form className="row g-3" onSubmit={handleSubmit} noValidate>
          <div className="col-md-6">
            <input
              type="text"
              name="name"
              className={`form-control ${errors.name ? 'is-invalid' : ''}`}
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {errors.name && <div className="invalid-feedback">{errors.name}</div>}
          </div>
          <div className="col-md-6">
            <input
              type="email"
              name="email"
              className={`form-control ${errors.email ? 'is-invalid' : ''}`}
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
          </div>
          <div className="col-12">
            <textarea
              name="message"
              className={`form-control ${errors.message ? 'is-invalid' : ''}`}
              rows="4"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            {errors.message && <div className="invalid-feedback">{errors.message}</div>}
          </div>
          <div className="col-12 text-center">
            <button type="submit" className="btn btn-primary btn-lg">Send Message</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
