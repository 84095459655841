import React, { useState, useEffect } from 'react';

function CarouselComponent() {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };
    useEffect(() => {
        const next = (index + 1) % 2;
        const id = setTimeout(() => setIndex(next), 3000);
        return () => clearTimeout(id);
    }, [index]);

    return (
        <div id="carouselExampleIndicators" className="carousel slide w3-animate-left" data-bs-ride="carousel">
            <div className="carousel-indicators">
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="0"
                    className={index === 0 ? "active" : ""}
                    aria-current={index === 0 ? "true" : "false"}
                    aria-label="Slide 1"
                    onClick={() => handleSelect(0)}
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="1"
                    className={index === 1 ? "active" : ""}
                    aria-label="Slide 2"
                    onClick={() => handleSelect(1)}
                ></button>
            </div>
            <div className="carousel-inner">
                <div className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                    <img src="1.webp" className="d-block w-100" alt="First Slide" />
                </div>
                <div className={`carousel-item ${index === 1 ? 'active' : ''}`}>
                    <img src="2.webp" className="d-block w-100" alt="Second Slide" />
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" onClick={() => handleSelect(index === 0 ? 1 : index - 1)}>
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" onClick={() => handleSelect(index === 1 ? 0 : index + 1)}>
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}

export default CarouselComponent;
